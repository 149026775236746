<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-06-29 16:14:29
 * @LastEditTime: 2022-06-30 10:44:57
-->
<template>
	<div class="voteSetDetail">
		<common-title :title="'评选设置详情'" />
		<div class="voteSetDetail-content">
			<div class="common-table">
				<div class="common-table-row">
					<div class="common-table-col">
						<span class="common-table-label">姓名</span>
						<el-input readonly="readonly" v-model="voteSetDetail.name" placeholder="请输入"
							class="common-table-val" />
					</div>
					<div class="common-table-col">
						<span class="common-table-label">学号</span>
						<el-input readonly="readonly" v-model="voteSetDetail.studentId" placeholder="请输入"
							class="common-table-val" />
					</div>
				</div>
				<div class="common-table-row">
					<div class="common-table-col">
						<span class="common-table-label">性别</span>
						<span class="common-table-text"><span>{{voteSetDetail.gender == true?"男":"女"}}</span></span>
					</div>
					<div class="common-table-col">
						<span class="common-table-label">总工时</span>
						<el-input readonly="readonly" v-model="voteSetDetail.workingHours" placeholder="请输入"
							class="common-table-val" />
					</div>
				</div>
				<div class="common-table-row">
					<div class="common-table-col">
						<span class="common-table-label">学院班级</span>
						<!-- <el-input readonly="readonly" v-model="voteSetDetail.collegeName" placeholder="请输入"
							class="common-table-val" /> -->
						<span class="common-table-text">{{voteSetDetail.collegeName}}{{voteSetDetail.faculty}}</span>
					</div>
					<div class="common-table-col">
						<span class="common-table-label">有效工时</span>
						<el-input v-model="voteSetDetail.efficient" placeholder="请输入" class="common-table-val" />
					</div>
				</div>
				<div class="common-table-row">
					<div class="common-table-col">
						<span class="common-table-label">年份</span>
						<el-input v-model="voteSetDetail.reviewYear" placeholder="请输入" class="common-table-val" />
					</div>
					<div class="common-table-col">
						<span class="common-table-label">提交时间</span>
						<el-input v-model="voteSetDetail.createTime" placeholder="请输入" class="common-table-val" />
					</div>
				</div>
				<div class="common-table-row">
					<div class="common-table-col">
						<span class="common-table-label">志愿服务心得</span>
						<el-input readonly="readonly" type="textarea" v-model="voteSetDetail.experience"
							placeholder="请输入" class="common-table-val" />
					</div>
				</div>
			</div>
			<div class="">
				<div class="voteSetDetail-title">
					工时表
				</div>
				<el-table :data="voteSetDetail.workingHoursList" border>
					<el-table-column width="65px" type="index" label="序号">
					</el-table-column>
					<el-table-column prop="studentId" label="学号">
					</el-table-column>
					<el-table-column prop="name" label="姓名">
					</el-table-column>
					<el-table-column prop="workingHours" label="工时数">
						<template slot-scope="scope">
							<span :class="{'action-red':scope.row.activityType == 3}">{{scope.row.workingHours}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="activityName" label="活动名称">
						<template slot-scope="scope">
							<span :class="{'action-red':scope.row.activityType == 3}">{{scope.row.activityName}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="activityTime" label="活动时间">
					</el-table-column>
				</el-table>
			</div>
			<div class="" v-if="voteSetDetail.status == 0">
				<div class="starVolunteerDetail-title">
					历史评定
				</div>
				<div v-for="(item, index) in voteSetDetail.starRecordSheets" :key="index" class="common-table">
					<!-- <div v-if="item.status!=0"> -->
						<div class="common-table-row">
							<div class="common-table-col">
								<span class="common-table-label">年份</span>
								<el-input readonly="readonly" v-model="item.reviewYear" placeholder="请输入"
									class="common-table-val" />
							</div>
							<div v-if="item.status == 1" class="common-table-col">
								<span class="common-table-label">星级评定</span>
								<span class="common-table-text">
									<i v-for="i in item.star" :key="i" class="iconfont icon-wujiaoxing"></i>
								</span>
							</div>
							<div v-if="item.status == 2" class="common-table-col">
								<span class="common-table-label">拒绝原因</span>
								<span class="common-table-text">
									{{item.reason}}
								</span>
							</div>
						</div>
						<div class="common-table-row">
							<div class="common-table-col">
								<span class="common-table-label">审核人</span>
								<el-input readonly="readonly" v-model="item.reviewPho" placeholder="请输入"
									class="common-table-val" />
							</div>
							<div class="common-table-col">
								<span class="common-table-label">通过时间</span>
								<el-input v-model="item.updateTime" placeholder="请输入" class="common-table-val" />
							</div>
						</div>
					<!-- </div> -->
				</div>
			</div>
			<div v-if="voteSetDetail.status != 0" class="">
				<div class="voteSetDetail-title">
					审核记录
				</div>
				<div class="common-table">
					<div class="common-table-row">
						<div class="common-table-col">
							<span class="common-table-label">审核人</span>
							<el-input readonly="readonly" v-model="voteSetDetail.reviewPho" placeholder="请输入"
								class="common-table-val" />
						</div>
						<div class="common-table-col">
							<span class="common-table-label">审核时间</span>
							<el-input readonly="readonly" v-model="voteSetDetail.updateTime" placeholder="请输入"
								class="common-table-val" />
						</div>
					</div>
					<div class="common-table-row">
						<div class="common-table-col">
							<span class="common-table-label">审核状态</span>
							<span class="common-table-text">{{voteSetDetail.status == 1?"通过":"拒绝"}}</span>
						</div>
						<div v-if="voteSetDetail.status == 1" class="common-table-col">
							<span class="common-table-label">星级评定</span>
							<span class="common-table-text">
								<i v-for="i in voteSetDetail.star" :key="i" class="iconfont icon-wujiaoxing"></i>
							</span>
						</div>
						<div v-else class="common-table-col">
							<span class="common-table-label">拒绝原因</span>
							<el-input v-model="voteSetDetail.reason" placeholder="请输入" class="common-table-val" />
						</div>
					</div>
				</div>
			</div>
			<div class="voteSetDetail-btn">
				<el-button @click="voteVerifyFn" type="primary">{{voteSetDetail.status == 0 ?'立即评定':'修改认定'}}</el-button>
			</div>
		</div>
		<el-dialog @close="closeDialog" title="提示" :modal="false" class="dialog-bg" :show-close="false"
			:visible.sync="dialogVisible" width="520px">
			<div slot="title" class="dialog-header">
				<span>{{dialogTitle}}</span>
				<img @click="closeDialog" src="@/assets/images/common/close.png">
			</div>
			<el-form :model="voteVerify" :rules="voteVerifyRules" ref="voteVerify" label-width="100px" class="">
				<el-form-item class="voteSetDetail-dia-formitem" label="是否通过:" prop="status">
					<div class="flex-column">
						<el-radio-group @change="verifyChange" class="voteSetDetail-dia-radio"
							v-model="voteVerify.status">
							<el-radio :label="1">通过</el-radio>
							<el-radio :label="2">拒绝</el-radio>
						</el-radio-group>
					</div>
				</el-form-item>
				<el-form-item v-if="voteVerify.status == 1" class="voteSetDetail-dia-formitem" label="星级:" prop="star">
					<el-select class="voteSetDetail-dia-select" v-model="voteVerify.star" placeholder="请选择">
						<el-option v-for="item in starList" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item v-if="voteVerify.status == 2" class="voteSetDetail-dia-formitem" label="拒绝原因:"
					prop="reason">
					<el-input type="textarea" class="voteSetDetail-dia-textarea" v-model="voteVerify.reason"
						placeholder="请输入">
					</el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="closeDialog">取 消</el-button>
				<el-button v-preventReClick @click="submitDialog" type="primary">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		detailVoteSet,
		verifyVoteSet,
	} from "@/api/volunteer";
	export default {
		data() {
			return {
				voteSetDetail: {
					status: 1
				},
				voteVerify: {
					status: 1
				},
				voteVerifyRules: {
					status: [{
						required: true,
						message: "请选择是否通过",
						trigger: "change"
					}],
					star: [{
						required: true,
						message: "请选择星级",
						trigger: "change"
					}],
					reason: [{
						required: true,
						message: "请输入拒绝原因",
						trigger: "change"
					}]
				},
				starList: [{
						label: "1星",
						value: 1
					},
					{
						label: "2星",
						value: 2
					},
					{
						label: "3星",
						value: 3
					},
					{
						label: "4星",
						value: 4
					},
					{
						label: "5星",
						value: 5
					},
				],
				dialogVisible: false,
				dialogTitle: "星级评定",
				voteId: "",
			};
		},
		watch: {
			dialogVisible: {
				handler(val) {
					if (!val) {
						this.$nextTick(() => {
							this.$refs["voteVerify"].clearValidate()
						})
					}
				},
				deep: true
			}
		},
		mounted() {
			this.voteId = this.$route.query.voteId
			this.getVoteSetDetail()
		},
		beforeRouteLeave(to,from,next){
			to.meta.isAlive = true
			next()
		},
		methods: {
			verifyChange(e) {
				console.log("verifyChange", e)
				if (e == 1) {
					this.$set(this.voteVerify, "reason", "")
				} else if (e == 2) {
					this.$set(this.voteVerify, "star", this.mapStarSection(this.voteSetDetail.efficient))
				}
			},
			getVoteSetDetail() {
				detailVoteSet(this.voteId, {
					id: this.voteId
				}).then((res) => {
					if (res.code == 0) {
						this.voteSetDetail = res.data
					}
				})
			},
			mapStarSection(intNum) {
				let mapNum = intNum * 1
				if (mapNum&&mapNum>0) {
					if (mapNum >= 100 && mapNum < 150) {
						return 1
					} else if (mapNum >= 150 && mapNum < 200) {
						return 2
					} else if (mapNum >= 200 && mapNum < 300) {
						return 3
					} else if (mapNum >= 300 && mapNum < 400) {
						return 4
					} else if (mapNum >= 400) {
						return 5
					} else {
						return ""
					}
				} else {
					return ""
				}

			},
			voteVerifyFn() {
				if (this.voteSetDetail.status == 0) {
					this.voteVerify = {
						status: 1,
						star: this.mapStarSection(this.voteSetDetail.efficient)
					}
				} else if (this.voteSetDetail.status == 1) {
					this.voteVerify = {
						status: 1,
						star: this.voteSetDetail.star ? this.voteSetDetail.star : 1
					}
				} else if (this.voteSetDetail.status == 2) {
					this.voteVerify = {
						status: 2,
						reason: this.voteSetDetail.reason
					}
				}
				this.dialogVisible = true
			},
			closeDialog() {
				this.dialogVisible = false
			},
			submitDialog() {
				this.$refs["voteVerify"].validate(valid => {
					if (valid) {
						let verifyData = JSON.parse(JSON.stringify(this.voteVerify))
						this.$set(verifyData, "volunteerId", this.voteSetDetail.volunteerId)
						console.log("verifyData", verifyData)
						verifyVoteSet(this.voteSetDetail.id, verifyData).then((res) => {
							if (res.code == 0) {
								this.$message({
									type: 'success',
									message: '评定成功!'
								});
								// this.voteSetDetail.status = verifyData.status
								this.getVoteSetDetail()
								this.dialogVisible = false
							}
						})
					}
				})
			},
		}
	};
</script>

<style lang="scss" scoped>
	.starVolunteerDetail-title {
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: bold;
		line-height: 22px;
		color: #1C2233;
		margin: 20px 0 10px 0;
	}

	.starVolunteerDetail-btn {
		display: flex;
		margin-top: 20px;
	}
	/deep/.el-input__inner {
		height: 36px;
		border-radius: 0px 2px 2px 0px;
		border: 0;
	}

	/deep/.voteSetDetail-btn .el-button--default {
		color: #606266 !important;
		border: 1px solid #DCDFE6 !important;
	}

	/deep/.el-textarea__inner {
		resize: none;
		height: 72px;
		border: 0;
	}

	.action-red {
		color: red;
	}

	.voteSetDetail {
		.voteSetDetail-dia-formitem {
			margin: 20px 20px 0 0;

			.voteSetDetail-dia-radio {
				margin: 12px 0 10px 0;
			}

			.voteSetDetail-dia-ipt {
				border: 1px solid #DCDFE6;
				border-radius: 4px;
			}

			.voteSetDetail-dia-select {
				border: 1px solid #DCDFE6;
				width: 398px;
			}

			.voteSetDetail-dia-textarea {
				border: 1px solid #DCDFE6;
				border-radius: 4px;
			}
		}

		.voteSetDetail-content {
			padding: 0 20px 20px 20px;

			.voteSetDetail-title {
				font-size: 16px;
				font-family: PingFang SC;
				font-weight: bold;
				line-height: 22px;
				color: #1C2233;
				margin: 20px 0 10px 0;
			}

			.voteSetDetail-btn {
				display: flex;
				margin-top: 20px;
			}
		}
	}
</style>
